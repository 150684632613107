import React, {useContext, useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';
import Slider from 'rc-slider';
import Sticky from "react-stickynode";
import {sortBy, min, max, find, uniq, map, filter, indexOf, forEach} from 'lodash';
import {VehicleTestDriveForm, VehicleOfferForm, BasicContactForm} from '../../../../core'
import {getStickiesOffset, formatNumber, formatPrice, b64DecodeUnicode, currentDateTime, getLastActivity, updateLastActivity, navigatePage, navigateAction} from "../../../../core/utils";
import MoreInformation from "../../../../core/components/vehicle/forms/MoreInformation";
import DataContext from "../../../../core/store/DataContext";

const offerFormID = 'black-and-white-detail-offer-form';
const moreInfoID = 'black-and-white-detail-more-info-form';
const testDriveID = 'black-and-white-detail-test-drive-form';

const DetailRightSide = ({vehicle, reservation, financing, context, onFinanceRateChanged, pdfEndpoint, financingRate, calculate_value_action}) => {
    const [testDriveFormVisible, setTestDriveFormVisible] = useState(false);
    const [moreInfoVisible, setMoreInfoVisible] = useState(false);
    const [offerFormVisible, setOfferFormVisible] = useState(false);
    const [financePrice, setFinancePrice] = useState(0);
    const [deposit, setDeposit] = useState(0);
    const [monthsDuration, setMonthsDuration] = useState(0);
    const [remarksExpanded, setRemarksExpanded] = useState(false);
    const {traceUserAction} = useContext(DataContext);
    const finance_rates = vehicle.financeRates || [];
    const deposits = sortBy(finance_rates.map(fr => parseInt(fr.deposit)).filter((value, index, self) => self.indexOf(value) === index));
    const months = sortBy(finance_rates.map(fr => parseInt(fr.months)).filter((value, index, self) => self.indexOf(value) === index));
    const [depositTraceTimeout, setDepositTraceTimeout] = useState(0);
    const [durationTraceTimeout, setDurationTraceTimeout] = useState(0);

    let monthMarks = {}
    let depositMarks = {}
    forEach(months, value => monthMarks = Object.assign(monthMarks, {[value]: ''}));
    forEach(deposits, value => depositMarks = Object.assign(depositMarks, {[value]: ''}));


    useEffect(() => {
        if (finance_rates.length > 0) {
            const default_finance = find(vehicle.financeRates, (fr) => fr.default);
            if (default_finance !== null && default_finance !== undefined) {
                setDeposit(default_finance.deposit);
                setMonthsDuration(default_finance.months);
                setFinancePrice(default_finance.monthlyPrice)
            } else {
                if (deposits[1] !== undefined) setDeposit(deposits[1]);
                if (months[1] !== undefined) setMonthsDuration(months[1]);
                setFinancePrice([])
            }
        }

    }, [vehicle]);

    useEffect(() => {
        const default_finance = find(vehicle.financeRates, (fr) => fr.default);
        if (deposit !== 0 && deposit !== default_finance.deposit) {
            handleDepositTracing();
        }
    }, [deposit]);

    useEffect(() => {
        const default_finance = find(vehicle.financeRates, (fr) => fr.default);
        if (monthsDuration !== 0 && monthsDuration !== default_finance.months) {
            handleDurationTracing();
        }
    }, [monthsDuration]);


    const updateFinancePrice = (newDeposit, newMonthsDuration) => {
        let rateResult = find(vehicle.financeRates, rate => parseInt(rate.deposit) === parseInt(newDeposit) && parseInt(rate.months) === parseInt(newMonthsDuration));
        if (rateResult !== undefined) {
            const newFinancePrice = rateResult.monthlyPrice;
            setFinancePrice(newFinancePrice);
            onFinanceRateChanged(rateResult)
        }
    };

    const saveActivity = (deposit, duration) => {
        let lastActivity = getLastActivity();
        if (lastActivity !== undefined) {
            lastActivity.stop = currentDateTime();
            lastActivity.data = {
                vehicleReference: vehicle.reference,
                financeSlidersChanged: true,
                financeSliderValues: {
                    depositPercentage: deposit,
                    durationMonths: duration
                }
            };
            updateLastActivity(lastActivity)
        }
    };

    const getDurationIndex = (value) => {
        return indexOf(months, value);
    }

    const findNextValue = (value) => {
        if (getDurationIndex(value) < 0) {
            const nextValue = value + 1;
            findNextValue(nextValue)
        }

        return value;
    }

    const handleDepositTracing = () => {
        if (depositTraceTimeout !== 0) {
            clearTimeout(depositTraceTimeout)
        }

        const timeout = setTimeout(() => {
                traceUserAction('vehicle-finance-deposit', JSON.stringify({reference: vehicle.reference, deposit: deposit}));
                clearTimeout(timeout)
            },
            3000
        );

        setDepositTraceTimeout(timeout);
    };

    const handleDurationTracing = () => {
        if (durationTraceTimeout !== 0) {
            clearTimeout(durationTraceTimeout)
        }

        const timeout = setTimeout(() => {
                traceUserAction('vehicle-finance-duration', JSON.stringify({reference: vehicle.reference, duration: monthsDuration}));
                clearTimeout(durationTraceTimeout)
            },
            3000
        );
        setDurationTraceTimeout(timeout);
    };

    const renderPrivateCustomerSliders = () => {
        return (
            <div className="">
                <div className="text-lg mb-5">Bereken uw financieringsplan</div>
                <div className="text-sm text-gray-600 mb-3">Voorschot</div>
                <Slider min={parseInt(min(deposits))}
                        max={parseInt(max(deposits))}
                        defaultValue={parseInt(deposit)}
                        value={parseInt(deposit)}
                        step={null}
                        marks={depositMarks}
                        dotStyle={{display: 'none'}}
                        onChange={newDeposit => {
                            saveActivity(newDeposit, monthsDuration);
                            updateFinancePrice(newDeposit, monthsDuration);
                            setDeposit(newDeposit);
                        }}/>

                <div className="w-full flex justify-end text-sm mt-2 text-gray-600">{deposit} %</div>


                <div className="text-sm text-gray-600 mb-3">Duurtijd van de financiering</div>
                <Slider min={parseInt(min(months))}
                        max={parseInt(max(months))}
                        defaultValue={parseInt(monthsDuration)}
                        value={parseInt(monthsDuration)}
                        step={null}
                        marks={monthMarks}
                        dotStyle={{display: 'none'}}
                        onChange={newMonths => {
                            saveActivity(deposit, newMonths);
                            updateFinancePrice(deposit, newMonths);
                            setMonthsDuration(newMonths);
                        }}/>
                <div className="w-full flex justify-end text-sm mt-2 text-gray-600">{monthsDuration} m</div>
            </div>
        )
    };

    const scrollToElement = id => {
        const elem = document.getElementById(id);

        if (elem) {
            const yOffset = getStickiesOffset();
            const y = (elem.getBoundingClientRect().top + window.pageYOffset) - yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }


    useEffect(() => {
        if (testDriveFormVisible) {
            scrollToElement(testDriveID);
        }

        if (moreInfoVisible) {
            scrollToElement(moreInfoID);
        }

        if (offerFormVisible && window.innerWidth <= 768) {
            scrollToElement(offerFormID);
        }

    }, [testDriveFormVisible, moreInfoVisible, offerFormVisible]);

    const distinctOptionGroups = uniq(map(vehicle.equipments, (eq) => eq.group_key));

    if (testDriveFormVisible) {
        return (
            <div id={testDriveID} className="flex flex-1 flex-col ">
                <Fade duration={500}>
                    <div className="top-0 right-0 bottom-0 left-0 z-10">
                        <VehicleTestDriveForm vehicle={vehicle}
                                              context={context}
                                              onClose={() => setTestDriveFormVisible(false)}
                                              handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}/>
                    </div>
                </Fade>
            </div>
        )
    }

    if (moreInfoVisible) {
        return (
            <div id={moreInfoID} className="flex flex-1 flex-col ">
                <Fade duration={500}>
                    <div className="top-0 right-0 bottom-0 left-0 z-10">
                        <MoreInformation vehicle={vehicle}
                                         context={context}
                                         onClose={() => setMoreInfoVisible(false)}
                                         handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}/>
                    </div>
                </Fade>
            </div>
        )
    }

    if (offerFormVisible) {
        const financialData = {
            'price': formatPrice(vehicle.price),
            'deposit': deposit,
            'months': monthsDuration,
            'financePrice': formatPrice(financePrice)
        }
        return (
            <div id={offerFormID} className="flex flex-1 flex-col ">
                <Fade duration={500}>
                    <div className="top-0 right-0 bottom-0 left-0 z-10">
                        <VehicleOfferForm vehicle={vehicle}
                                          financialData={financialData}
                                          context={context}
                                          handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}
                                          onClose={() => setOfferFormVisible(false)}
                        />
                    </div>
                </Fade>
            </div>
        )
    }

    return (
        <div className="flex flex-col relative w-full">
            <div className="md:hidden bg-white mb-3">
                <Sticky enabled={true} top={getStickiesOffset()} bottomBoundary='.bottom-container' innerZ={25}>
                    <div className="flex flex-col">
                        {reservation && <div className="flex w-full mb-2">
                            <div onClick={() => navigatePage(context, context.urls.vehicleReservation, `vehicle=${vehicle.reference}`)}
                                 className="bg-primary border-2 border-transparent text-white hover:bg-white hover:border-2 hover:border-primary hover:text-primary py-2 px-4 rounded-none w-full text-center cursor-pointer outline-none">
                                Reserveren
                            </div>
                        </div>}
                        <div className="bg-white flex ">
                            <div className="flex flex-1 mr-2">
                                <button className="bg-gray-900 w-full text-white hover:text-gray-400 font-medium text-sm py-2 px-2 rounded-none mb-2 outline-none"
                                        onClick={() => {
                                            setMoreInfoVisible(false);
                                            setTestDriveFormVisible(true);
                                        }}>
                                    Testrit aanvragen
                                </button>
                            </div>
                            <div className="flex flex-1 cursor-pointer outline-none" style={{width: '100%'}}>
                                <div className="bg-gray-900 w-full text-white hover:text-gray-400 font-medium text-sm py-2 px-2 rounded-none mb-2 outline-none text-center" onClick={() => {
                                    setTestDriveFormVisible(false);
                                    setMoreInfoVisible(true);
                                }}>
                                    Meer informatie
                                </div>
                            </div>
                        </div>
                    </div>
                </Sticky>
            </div>


            {financing && finance_rates.length > 0 && <div className="flex flex-col md:flex-row border border-gray-100 items-center mb-5">
                <div className="flex flex-col w-full md:w-5/8 p-5 ">
                    <div className="block md:hidden mb-8 text-center w-full text-gray-600">Let op, geld lenen kost ook geld.</div>
                    <div className="w-full">{renderPrivateCustomerSliders()}</div>
                    <div className="hidden md:block mt-8 text-center w-full text-gray-600">Let op, geld lenen kost ook geld.</div>
                </div>
                <div className="flex flex-col items-center justify-start w-full md:w-3/8 bg-gray-100 p-5">
                    <span className="mb-5 text-lg">Per maand</span>
                    <div className="flex items-center mb-12">
                        <div className="flex self-end text-xl md:text-3xl "><Fade spy={financePrice} duration={200}>{formatPrice(financePrice)}</Fade>*</div>
                        <span className="flex self-end text-smfont-extralight ml-2 text-gray-700" style={{paddingBottom: 3}}>{vehicle.vat_label}</span>
                    </div>
                    <button className="w-full bg-primary text-white hover:text-gray-400 font-medium text-sm py-2 px-2 rounded-none mb-12" onClick={() => setOfferFormVisible(true)}>
                        Een vrijblijvende offerte aanvragen
                    </button>


                    <div className="flex flex-col text-sm text-black text-center">
                        <div>Wij nemen alle wagens over.</div>


                        {calculate_value_action.type !== "" &&
                        <div className="hover:text-black mt-1 text-center underline cursor-pointer outline-none" onClick={() => navigateAction(context, calculate_value_action)}>
                            <div className="flex flex-row justify-between w-full">
                                <div className='py-3 px-4'> {calculate_value_action.title}</div>
                            </div>
                        </div>}


                        {/*<div className="hover:text-black mt-1 text-center underline cursor-pointer outline-none" onClick={() => navigatePage(context, context.urls.vehicleTakeOver)}>*/}
                        {/*    Bereken de waarde van uw auto hier.*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>}

            {financing && finance_rates.length > 0 && financingRate && <div className="mt-5 px-5 flex md:hidden">
                <div className="text-gray-600 text-center mb-6" dangerouslySetInnerHTML={{__html: b64DecodeUnicode(financingRate.legal)}}/>
            </div>}

            {/*<div className="mt-5 px-5">{legalFormContent()}</div>*/}
            <div className="mb-5 bg-white">
                <div className="flex justify-between px-2 w-full text-black mb-5 border-b border-primary bg-gray-100 py-2">
                    <div>Kenmerken</div>
                </div>

                <div className="flex flex-col w-full mb-1 md:mb-2">
                    <div className="flex flex-col md:flex-row w-full">
                        <div className="flex w-full md:w-1/2 mb-1 md:mb-0">
                            <div className="text-md text-gray-600 w-1/2">Brandstof</div>
                            <div className="text-md text-gray-900 w-1/2 font-extrabold capitalize">{vehicle.fuel}</div>
                        </div>
                        <div className="flex w-full md:w-1/2">
                            <div className="text-md text-gray-600 w-1/2">Transmissie</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold capitalize">{vehicle.gearbox}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full mb-1 md:mb-2">
                    <div className="flex flex-col md:flex-row w-full">
                        <div className="flex w-full md:w-1/2 mb-1 md:mb-0">
                            <div className="text-md text-gray-600 w-1/2">Kilometers</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{formatNumber(vehicle.mileage)} km</div>
                        </div>
                        <div className="flex w-full md:w-1/2">
                            <div className="text-md text-gray-600 w-1/2">Eerste inschrijving</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{vehicle.firstRegistrationMonth} / {vehicle.firstRegistrationYear}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full mb-1 md:mb-2">
                    <div className="flex flex-col md:flex-row w-full">
                        <div className="flex w-full md:w-1/2 mb-1 md:mb-0">
                            <div className="text-md text-gray-600 w-1/2">Vermogen</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{vehicle.kw} kw/{vehicle.hp} pk</div>
                        </div>
                        <div className="flex w-full md:w-1/2">
                            <div className="text-md text-gray-600 w-1/2">Garantie</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{(vehicle.warranty && vehicle.warranty > 0) ? `${vehicle.warranty} jaar` : '/'}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full mb-1 md:mb-2">
                    <div className="flex flex-col md:flex-row w-full">
                        <div className="flex w-full md:w-1/2 mb-1 md:mb-0">
                            <div className="text-md text-gray-600 w-1/2">Deuren</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{vehicle.doors} deuren</div>
                        </div>
                        <div className="flex w-full md:w-1/2">
                            <div className="text-md text-gray-600 w-1/2">Zitplaatsen</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{vehicle.seats} zitplaatsen</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full mb-1 md:mb-2">
                    <div className="flex flex-col md:flex-row w-full">
                        <div className="flex w-full md:w-1/2 mb-1 md:mb-0">
                            <div className="text-md text-gray-600 w-1/2">CO2</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{vehicle.co2} g/km</div>
                        </div>
                        <div className="flex w-full md:w-1/2">
                            <div className="text-md text-gray-600 w-1/2">Cilinderinhoud</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{formatNumber(vehicle.engineCc)} cc</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-full">
                    <div className="flex flex-col md:flex-row w-full">
                        <div className="flex w-full md:w-1/2 mb-1 md:mb-0">
                            <div className="text-md text-gray-600 w-1/2">Euronorm</div>
                            <div className="text-md text-gray-900 w-1/2 font-bold">{vehicle.euroNorm}</div>
                        </div>
                        <div className="flex w-full md:w-1/2">

                        </div>
                    </div>
                </div>
            </div>

            <div className={`flex flex-col md:flex-row items-center ${vehicle.carpass && vehicle.carpass !== "" ? "justify-between" : "justify-end"}`}>
                {vehicle.carpass && vehicle.carpass !== "" && <a href={vehicle.carpass} target='_blank' rel='noopener noreferrer'>
                    <img className="cursor-hand" src={require('../../../../core/assets/carpass_black.png')} style={{width: 150}} alt="carpass"/>
                </a>}

                <a href={pdfEndpoint} target="_blank" className="flex text-black items-center hover:text-gray-800 font-bold underline mt-5 md:mt-0">
                    <i className="fal fa-arrow-alt-to-bottom mr-2"/> <span>Download voertuigfiche</span>
                </a>
            </div>

            {/*{contactPersonBox()}*/}

            <div className="mt-5">
                <div className="flex pl-2 w-full pl-0 text-black mb-5 border-b border-primary bg-gray-100 py-2">
                    Opties
                </div>


                {map(distinctOptionGroups, (group, index) => {
                    const options = map(filter(vehicle.equipments, (eq) => eq.group_key === group), (opt) => opt.value);
                    const even = index % 2 === 0;
                    let group_label = group;
                    try {
                        group_label = find(vehicle.equipments, eq => eq.group_key === group).group
                    } catch (e) {

                    }
                    return (
                        <div key={index} className="flex flex-col flex-1 p-2">
                            <div className="flex flex-row font-bold mb-1">{group_label}</div>
                            <div className="flex flex-wrap p-2">
                                {map(options.sort(), (option, i) => {
                                    return (
                                        <div key={i} className={`w-full md:w-1/2 capitalize text-gray-700 text-md truncate mb-1 md:mb-2 truncate ${even ? "pr-0 md:pr-4" : "pl-0"}`}>
                                            <i className="fal fa-check"/> {option}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>


            {vehicle.comments &&
            <div className="bg-gray-100">
                <div className="flex pl-2 pl-0 text-black border-b border-primary bg-gray-100 py-2">
                    Opmerkingen
                </div>
                <div className="overflow-hidden cursor-hand pl-2 pt-2 pr-2 cursor-pointer font-body" onClick={() => setRemarksExpanded(!remarksExpanded)}
                     style={{height: (!remarksExpanded) ? 100 : 'auto'}}
                     dangerouslySetInnerHTML={{__html: b64DecodeUnicode(vehicle.comments)}}/>
                <div className="flex justify-between cursor-pointer pl-2 pr-2 pb-2" onClick={() => setRemarksExpanded(!remarksExpanded)}>
                    <span>...</span>
                    <div>
                        <i className={`mr-1 ${remarksExpanded ? 'fal fa-chevron-up' : 'fal fa-chevron-down'}`}/><span
                        className=""> {remarksExpanded ? 'minder opmerkingen ' : 'alle opmerkingen '} </span>
                    </div>
                </div>

            </div>}

            <div className="text-sm mt-4">
                * Wij zijn niet verantwoordelijk voor eventuele fouten en of misberekeningen in deze advertentie.
            </div>


        </div>

    );
};

export default DetailRightSide;
