import {useState, useEffect, useContext} from 'react';
import fetch from 'cross-fetch'
import DataContext from "../store/DataContext";
import {map_filters_v2, map_vehicles} from "./useVehiclesData";

export function useVehiclesDataLazy(culture) {
    const contextData = useContext(DataContext);
    const [loading, setLoading] = useState(contextData.vehicles === undefined);
    const [dataFetchStarted, setDataFetchStarted] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = (qry, vehFilters, vehSearchFilters) => {
        setDataFetchStarted(true);
        setLoading(true);

        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': culture},
                body: JSON.stringify({query: qry, variables: {vehiclesFilter: vehFilters, filtersFilter: vehSearchFilters}}),
            })
            .then(res => res.json())
            .then(res => {
                contextData.setVehiclesData(map_vehicles(res.data.vehicles), map_filters_v2(res.data.vehiclesFilter, res.data.dataSettings), culture);
                setLoading(false);
                setDataFetchStarted(false);
            })
            .catch(error => {
                setError("ERROR");
                setLoading(false);
                setDataFetchStarted(false);
            });
    }

    return {
       fetchVehicles: (qry, vehFilters, vehSearchFilters) => fetchData(qry, vehFilters, vehSearchFilters)
    };
}
