import React, {useRef, useState} from 'react';
import {keys} from 'lodash';
import {Button, ErrorIndicator, InputField, SuccessIndicator} from "../../index";
import {getWindowPathLocation, hasValue, isValidEmail} from "../../utils";
import {useDealerContact} from "../../hooks/useDealerContact";


const ContactFormFields = {
    email: 'email',
    phone: 'phone',
    message: 'message',
    privacy: 'privacy',
    origin: 'origin',
}

const initialData = {
    [ContactFormFields.email]: "",
    [ContactFormFields.phone]: "",
    [ContactFormFields.message]: "",
    [ContactFormFields.privacy]: false,
    [ContactFormFields.origin]: ""
};


const BasicContactForm = ({context, handlePrivacy, lightForm}) => {
    const {submit, loading, dealerContactTypes} = useDealerContact();
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [data, setData] = useState(initialData);
    const textarea = useRef(null);

    const validate = () => {
        let errors = {};

        if (!hasValue(data.email)) {
            errors[ContactFormFields.email] = "Ongeldig e-mailadres";
        } else if (!isValidEmail(data.email)) {
            errors[ContactFormFields.email] = "Invalid email address";
        }

        if (!hasValue(data.message)) {
            errors[ContactFormFields.message] = "Vul aub uw een bericht in.";
        }

        if (!hasValue(data.phone)) {
            errors[ContactFormFields.phone] = "Vul aub uw gsm nummer in.";
        }

        const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]+$/;
        if(!phoneRegex.test(data.phone)){
            errors[ContactFormFields.phone] = "Het formaat van het ingevulde telefoon is ongeldig";
        }

        if (!data.privacy) {
            errors[ContactFormFields.privacy] = "Gelieve akkoord te gaan met de privacy regels";
        }

        setErrors(errors);

        return keys(errors).length <= 0;
    };

    const setValue = (field, value) => {
        setData({
            ...data,
            [field]: value
        })
    };

    const hasError = (field) => {
        return (errors && errors[field]) !== undefined
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setSuccess(false);

        if (validate()) {


            const jsonData = JSON.stringify({
                ...data,
                origin: getWindowPathLocation(context),
            });

            submit(dealerContactTypes.general, jsonData)
                .then(result => {
                    if (result) {
                        setSuccess(true);
                        setErrors(undefined);
                        setData(initialData);
                    } else {
                        setErrors({
                            result: 'Probleem met het verzenden van uw aanvraag.'
                        })
                    }
                })
        }
    };

    // console.log("errors", errors)
    console.log("errors[ContactFormFields.phone]", (errors && errors[ContactFormFields.phone]) ? errors[ContactFormFields.phone] : "NEMA")

    return (
        <React.Fragment>
            {errors && errors['result'] &&
            <div className="mb-4 px-5 md:px-0" style={{minHeight: 32}}>
                <ErrorIndicator error={errors["result"]}/>
            </div>}

            {success &&
            <div className="mb-4 px-5 md:px-0" style={{minHeight: 32}}>
                <SuccessIndicator success="Bedankt! Een van onze collega's beantwoordt uw bericht zo spoedig mogelijk."/>
            </div>}

            <div className="flex flex-1">

                <form action="" onSubmit={handleSubmit} className="rounded flex flex-col flex-1 ">
                    <div className="-mx-3 md:flex flex-col mb-5">
                        <div className="w-full px-3">
                            <InputField containerClass={`appearance-none block w-full focus:outline-none ${lightForm ? "focus:border-gray-800 rounded-none" : "focus:border-gray-300 rounded"}`}
                                        label="Email"
                                        placeholder="john.doe@gmail.com"
                                        value={data.email}
                                        onChange={(value) => setValue(ContactFormFields.email, value)}
                                        errorText={(errors && errors[ContactFormFields.email]) ? errors[ContactFormFields.email] : undefined}
                                        lightForm={lightForm}
                                        required={true}/>
                        </div>
                    </div>

                    <div className="-mx-3 md:flex flex-col mb-5">
                        <div className="w-full px-3">
                            <InputField containerClass={`appearance-none block w-full focus:outline-none ${lightForm ? "focus:border-gray-800 rounded-none" : "focus:border-gray-300 rounded"}`}
                                        label="Telefoon"
                                        placeholder="+32 123 45 56 67"
                                        value={data.phone}
                                        onChange={(value) => setValue(ContactFormFields.phone, value)}
                                        errorText={(errors && errors[ContactFormFields.phone]) ? errors[ContactFormFields.phone] : undefined}
                                        lightForm={lightForm}
                                        isPhone={true}
                                        // onlyNumbers={true}
                                        required={true}/>
                        </div>
                    </div>

                    <div className={`w-full flex flex-col  ${(hasError(ContactFormFields.message) ? 'mb-2' : 'mb-2')}`}>
                        <div className="w-full mb-6 md:mb-0">
                            <label className={`block tracking-wide mb-2 ${hasError(ContactFormFields.message) ? "text-red-400" : lightForm ? "text-white" : "text-gray-700"}`} htmlFor="grid-message">
                                Wat is uw vraag?
                            </label>
                            <textarea
                                ref={textarea}
                                placeholder="Graag zou ik meer willen weten..."
                                className={`appearance-none block w-full  py-3  ${lightForm ? "focus:border-gray-400 text-white rounded-none px-0" : "h-30 border focus:border-primary text-primary rounded px-4"}  focus:outline-none ${hasError(ContactFormFields.message) ? "bg-red-100 border-red-400 text-red-900" : lightForm ? "bg-transparent border-b border-white" : "bg-white border-gray-400"}`}
                                id="grid-message"
                                onChange={e => setValue(ContactFormFields.message, e.target.value)}
                                onFocus={() => textarea.current.select()}
                                value={data.message}
                            />
                        </div>
                        {/*{errors && errors[ContactFormFields.message] && <div className="text-red-400 text-xs mt-1">{errors[ContactFormFields.message]}</div>}*/}
                    </div>

                    <div className="mb-5">
                        <div className={`text-primary flex text-xs ${(hasError(ContactFormFields.privacy) ? "text-red-400" : "text-gray-700")}`}>
                            <input type="checkbox" checked={data.privacy} onChange={e => setValue(ContactFormFields.privacy, e.target.checked)}/>
                            <span className={`mr-1 ml-2 ${(hasError(ContactFormFields.privacy) ? "text-red-400" : lightForm ? "text-white" : "text-gray-700")}`}>Ik ga akkoord met het </span>
                            <span className={`underline cursor-pointer ${(hasError(ContactFormFields.privacy) ? "text-red-400" : lightForm ? "text-white" : "text-gray-700")}`}
                                  onClick={() => (handlePrivacy) ? handlePrivacy() : null}>privacybeleid</span>.
                        </div>
                    </div>


                    <div className="w-full flex items-center flex-col lg:flex-row">
                        <div className="w-full flex justify-end">
                            <Button disabled={loading}
                                    onClick={e => handleSubmit(e)}
                                    loading={loading}
                                    loadingText="Bezig met verzenden..."
                                    text="Versturen"
                                    lightForm={lightForm }
                            />
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

export default BasicContactForm;
