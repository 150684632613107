import React from "react";
import {getKeyValueContentFieldsForBlock} from "../utils"
import * as components from '../dealer-theme/'

const Block = ({block, context, store, overridePageMetaData}) => {
        const Component = components[block.variant];

        if (!Component) {
            return null;
        }

        return (
            <div id={`b${block.id.split('-')[0]}`}>
                <Component.default  {...getKeyValueContentFieldsForBlock(block, context.culture)}
                                    context={context}
                                    store={store}
                                    overridePageMetaData={overridePageMetaData}
                />
            </div>
        )
    }
;

export default Block;

