import React, {useEffect, useState} from "react"
import {GlobalModal} from '../dealer-theme/core';
import {getCookie} from "../dealer-theme/core/utils";
import '../custom.css';
import {CookieBanner, FinanceBanner, VehicleCompareBanner} from '../dealer-theme/';

const Layout = props => {
    const {context} = props;
    const [financingAccepted, setFinancingAccepted] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);


    useEffect(() => {
        const cookie = getCookie('GDPR_ANALYTICS');
        const accepted = cookie !== undefined;
        setCookiesAccepted(accepted);

        // Legal accepted
        const legalCookie = getCookie('LEGAL_ACCEPTED');
        const legalAccepted = legalCookie !== undefined;
        setFinancingAccepted(legalAccepted);

    }, []);

    return (
        <div className="font-body" itemscope="" itemtype="http://schema.org/AutoDealer">
            {props.children}


            <FinanceBanner.default visible={(context && context.financing) ? !financingAccepted : false}
                           location="bottom"
                           context={context}/>

            <CookieBanner.default visible={!cookiesAccepted}
                          location="bottom"
                          context={context}
            />

            <GlobalModal context={context}/>

            <VehicleCompareBanner.default context={context}/>
        </div>
    )
};

Layout.propTypes = {};

export default Layout
