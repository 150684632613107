export const customerSettings = {
    META_TITLE: "Dealer DEV",
    META_DESCRIPTION: "",
    SITEMAP_OUTPUT: '/var/www/website/sitemap.xml',
    DOMAIN: 'https://website.demoremarketing.com',
    CMS_USERNAME: "autralis",
    CMS_PASSWORD: "Autralis4Apple",
    GOOGLE_IDENTIFICATION: "_OgJqR-oBqDZuCm1tQH8aRAyqfKJR33izpJ3oCPQA6E",
    GOOGLE_ANALYTICS_ID: '',
    INTERCOM_ID: 'qzdv8h57',
    HOTJAR_ID: '',
    FINANCING: true,
    RESERVATION: false,
    FAVORITES: false,
    FINANCING_TEXT: true,
    VEHICLE_COMPARE: false,

};
