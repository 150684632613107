import React, {useEffect, useRef, useState} from "react"
import {useIsMobile} from "../hooks/useIsMobile";
import {useSwipeable} from "react-swipeable";
import {LoadingIndicator} from "../index";
import {useTranslation} from "react-i18next";
import {LazyLoadImage, LazyLoadComponent} from 'react-lazy-load-image-component';

// Component to show images with (custom) arrows, NOTHING ELSE !!
// images in array like [{"uri": images.uri}]


const Carousel = ({images, reference, onImageLoad, fetchingImages, renderArrows = null, style, onAfterChange = null, onMouseEnter, onVehicleClick, swipeEnabled, defaultImage, showArrowsAlways, imagesFetched}) => {
    const [index, setIndex] = useState(0);
    const [firstImageLoaded, setFirstImageLoaded] = useState(false);
    const [cardWidth, setCardWidth] = useState(0);
    const [imageContainerHeight, setImageContainerHeight] = useState(260);
    const {t} = useTranslation();
    const {isMobile} = useIsMobile();
    const containerRef = useRef();
    const defaultVehicleImage = defaultImage ? defaultImage : require("../assets/vehicle-default.jpg");

    useEffect(() => {
        function handleResize() {
            const newHeight = window.innerWidth < 768 ? 220 : 260;
            setImageContainerHeight(newHeight)
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        const newHeight = window.innerWidth < 768 ? 220 : 260;
        setImageContainerHeight(newHeight)
    }, [])

    const IMAGE_HEIGHT = imageContainerHeight

    useEffect(() => {
        if (containerRef && containerRef.current) {
            setCardWidth(containerRef.current.clientWidth)
        }
    }, [firstImageLoaded])

    useEffect(() => {
        if (swipeEnabled) {
            if (isMobile && fetchingImages !== undefined) {
                if (!fetchingImages && images.length > 1) {
                    setIndex(index + 1);
                }
            }
        }
    }, [fetchingImages, images])

    const handlePrevClick = (e) => {
        e.stopPropagation();
        const newIndex = index > 0 ? index - 1 : images.length - 1;
        setIndex(newIndex);
    };

    const handleNextClick = (e) => {
        e.stopPropagation();
        const newIndex = index + 1 < images.length ? index + 1 : 0;
        setIndex(newIndex);
    };

    useEffect(() => {
        if (onAfterChange) {
            onAfterChange(index)
        }
    }, [index])

    const handlersOptions = swipeEnabled ? {
        onSwipedLeft: () => {
            onMouseEnter && typeof onMouseEnter === "function" && onMouseEnter();
            if (index < images.length - 1) {
                const newIndex = index + 1 < images.length ? index + 1 : 0;
                setIndex(newIndex);
            }
        },
        onSwipedRight: () => {
            onMouseEnter && typeof onMouseEnter === "function" && onMouseEnter();
            if (index > 0) {
                const newIndex = index > 0 ? index - 1 : images.length - 1;
                setIndex(newIndex);
            }
        }
    } : {};


    const handlers = useSwipeable({
        ...handlersOptions,
        onTap: () => onVehicleClick(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });

    const onLoad = (e) => {
        if (!firstImageLoaded) {
            setFirstImageLoaded(true);
        }

        if (onImageLoad && typeof onImageLoad === 'function') {
            onImageLoad();
        }
    }

    const renderLoadingLazyImage = () => {
        return <div className="flex flex-1 items-center justify-center bg-white h-full"
                    style={{width: cardWidth, minHeight: 220}}>
            <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
            </svg>
        </div>
    }

    const renderImagesSlider = () => {
        let left = index * cardWidth;
        return <>
            {images && images.length < 1 && <img src={defaultVehicleImage}
                                                 alt={`vehicle_${reference}`}
                                                 style={{maxWidth: "100%", pointerEvents: 'none', objectFit: 'cover', height: "100%"}}
                                                 onLoad={onLoad}
            />}

            {images && images.length >= 1 && <div className="images-container flex flex-nowrap relative items-start h-full " style={{left: -left}}>
                {images.filter(img => img).map((img, i) => {
                    const initiallyLoaded = i < index + 1;
                    return <LazyLoadComponent>
                        <div className="h-full" style={{width: cardWidth}}>
                            <LazyLoadImage src={img.uri}
                                           key={`slider-image-${img.uri}-${i}`}
                                           alt={`vehicle-image-${i}`}
                                           threshold={1}
                                           visibleByDefault={initiallyLoaded}
                                           placeholder={renderLoadingLazyImage()}
                                           style={{maxWidth: "100%", pointerEvents: 'none', objectFit: 'cover', height: "100%"}}
                                           onLoad={onLoad}
                            />
                        </div>
                    </LazyLoadComponent>
                })}
            </div>}
        </>
    }

    const renderMobile = () => {
        return <div ref={containerRef} className="relative">
            {fetchingImages && <div className="flex flex-col justify-center items-center absolute top-0 right-0 left-0 bottom-0 z-10" style={{background: "rgba(0, 0, 0, 0.6)"}}>
                <div style={{height: 75}}><LoadingIndicator color="white"/></div>
                <div className="paragraph-small mt-2 text-white">{t('Loading images')}</div>
            </div>}
            <div style={{...style, height: IMAGE_HEIGHT, width: cardWidth}} className="carousel-image relative" {...handlers}>
                {renderImagesSlider()}
            </div>
            {(images.length > 1) &&
            <React.Fragment>
                {(renderArrows) ? renderArrows(handlePrevClick, handleNextClick) :
                    <div className="flex absolute bottom-0 right-0 mr-1 mb-1 text-white">
                        <div className="flex items-center justify-center cursor-pointer z-20 mr-1" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={handlePrevClick}>
                            <i className="fad fa-chevron-left text-xs "/>
                        </div>

                        <div className="flex items-center justify-center cursor-pointer z-20" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={handleNextClick}>
                            <i className="fad fa-chevron-right text-xs "/>
                        </div>
                    </div>
                }

            </React.Fragment>}
        </div>
    }

    if (isMobile) {
        return renderMobile();
    }

    return (
        <div className="relative" onMouseEnter={onMouseEnter}>

            <div ref={containerRef} className="relative">
                <div style={{...style, height: IMAGE_HEIGHT, width: cardWidth}} className="carousel-image">
                    {renderImagesSlider()}
                </div>

                {images.length > 1 &&
                <React.Fragment>
                    {(renderArrows) ? renderArrows(handlePrevClick, handleNextClick) :
                        <div className="flex absolute bottom-0 right-0 mr-1 mb-1 text-white">
                            <div className="flex items-center justify-center cursor-pointer z-20 mr-1" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={handlePrevClick}>
                                <i className="fad fa-chevron-left text-xs "/>
                            </div>

                            <div className="flex items-center justify-center cursor-pointer z-20" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={handleNextClick}>
                                <i className="fad fa-chevron-right text-xs "/>
                            </div>
                        </div>
                    }

                </React.Fragment>}

            </div>
        </div>
    )
};

Carousel.defaultProps = {
        fetchingImages: undefined,  // bugfix not al pictures are lazy loaded; otherwise useffect for index is not corrct
        images: [],
        swipeEnabled: true,
        style: {minHeight: 240, overflow: "hidden"},
        defaultImage: undefined,
        showArrowsAlways: false,
        imagesFetched: false
    };

export default Carousel
