import {filter, keyBy, mapValues, map} from "lodash";
import settings from "../config";

export const parseField = (field) => {
    if (field.type && field.type.startsWith("Json")) {
        return JSON.parse(field.value)
    } else {
        return field.value;
    }
};

export const parseFields = (fields) => {
    const parsed = map(fields, (field) => ({
        ...field,
        value: parseField(field)
    }));
    return parsed;
};

export const getFieldsForContent = (block, culture) => {
    const filtered = filter(block.content, (content) => content.culture === culture);
    if (filtered.length >= 1) {
        return parseFields(filtered[0].fields);
    }
    return null;
};

export const getKeyValueContentFieldsForBlock = (block, culture) => {
    const content = getFieldsForContent(block, culture);
    return mapValues(keyBy(content, 'name'), 'value');
};

export const isBrowserSupported = () => {
    const {detect} = require('detect-browser');
    const browser = detect();
    const name = browser.name;

    if (name === 'ie') {
        return false;
    } else {
        return true;
    }


    let supported;
    switch (name) {
        case 'chrome':
        case 'firefox':
        case 'edge':
        case 'safari':
        case 'ios':
        case 'crios':
            supported = true;
            break;
        default:
            supported = false;
    }
    return supported;
};

export const mergeText = (text, textToAdd, separator = '|') => {
    if (text.length > 0 && separator !== undefined) {
        if (text.includes(separator)) {
            return `${text} ${textToAdd}`
        } else {
            return `${text} ${separator} ${textToAdd}`
        }
    }

    return textToAdd;
};
