import React, {useEffect, useState} from "react"
import {isBrowserSupported, mergeText} from "./utils";
import {orderBy} from "lodash";
import Layout from "./components/Layout";
import SEO from "./components/Seo";
import BrowserNotSupported from "./components/not-supported/BrowserNotSupported";
import Block from "./components/Block";
import DataContext from "./dealer-theme/core/store/DataContext";
import CaptchaProvider from "./dealer-theme/core/components/CaptchaProvider";
import settings from "../config";
import {useVehiclesDataLazy} from "./dealer-theme/core/hooks/useVehiclesDataLazy";
import themeConfig from './dealer-theme/theme/config'

const Page = (props) => {
    const {page, meta, language, componentContext} = props.pageContext;
    const [pageMeta, setPageMeta] = useState(meta);
    const {fetchVehicles} = useVehiclesDataLazy(componentContext.culture);

    useEffect(() => {
        setPageMeta(meta)
    }, [meta]);

    useEffect(() => {
        if(settings.VEHICLES_PRELOAD && page.url !== themeConfig.urls.vehicles) {
            fetchVehicles(themeConfig.allVehiclesPreloadQuery, null, null)
        }
    }, []);

    const browserSupported = isBrowserSupported();
    if (!browserSupported) {
        return (
            <div className="font-body bg-gray-100">
                <BrowserNotSupported/>
            </div>)
    }

    const overridePageMetaData = (text) => {

        const title = (text && (meta && meta.title)) ? mergeText(meta.title, text) : "";
        const description = (text && (meta && meta.title)) ? mergeText(meta.description, text, undefined) : "";

        setPageMeta({
            ...pageMeta,
            title: title,
            description: description
        });
    };



    const orderedBlocks = orderBy(page.blocks, 'order');

    return (
        <Layout context={componentContext}>
            <CaptchaProvider captchaKey={settings.CAPTCHA_KEY}>
                <DataContext.Consumer>


                    {value => (
                        <React.Fragment>
                            <SEO title={(pageMeta && pageMeta.title) ? pageMeta.title : undefined}
                                 description={(pageMeta && pageMeta.description) ? pageMeta.description : undefined}
                                 lang={language}
                            />

                            {orderedBlocks.map((block) => {
                                return <Block block={block}
                                              key={block.id}
                                              context={componentContext}
                                              store={value}
                                              overridePageMetaData={overridePageMetaData}
                                />
                            })}
                        </React.Fragment>
                    )}
                </DataContext.Consumer>
            </CaptchaProvider>
        </Layout>

    )
};

export default Page
