import React, {useEffect, useState, useRef} from "react"
import Slider from "react-slick"
import {findIndex, head, sortBy, map, filter} from 'lodash';
import loadable from "@loadable/component";

const Lightbox = loadable(() => import('react-image-lightbox'))
import './PhotoSlider.css';
import useWindowDimensions from "../hooks/useWindowDimensions";
import {useTranslation} from "react-i18next";
import Turntable from "./common/image-turntable/Turntable";
import {useSwipeable} from "react-swipeable";


const PhotoSlider = ({images, images360, onePhotoSlider, className, showThumbs, photosSorted, thumbsPosition, enabled3D, renderSlide, renderThumbs, renderAllPicturesThumb, thumbImgClassName, imgClassName, vehicleId, defaultImage, multipleImages}) => {
    const [current, setCurrent] = useState('');
    const [currentLightBoxIndex, setCurrentLightboxIndex] = useState(0);
    const [lightboxWidth, setLightboxWidth] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);

    const [sliderImageIndex, setSliderImageIndex] = useState(0);
    const [sliderImageWidth, setSliderImageWidth] = useState(0);
    const [sliderContentWidth, setSliderContentWidth] = useState(0);

    const [thumbsHeight, setThumbsHeight] = useState(0);
    const [interior, setInterior] = useState(true);
    const {width, height} = useWindowDimensions();
    const {t} = useTranslation();

    const sliderContainer = useRef(null);
    const slider = useRef(null);
    const lightboxContainer = useRef(null);
    const imgRef = useRef();

    const isArray = images instanceof Array;
    const [images360Visible, setImages360Visible] = useState(images360 && images360.length > 0)


    useEffect(() => {
        setCurrent('');
        setCurrentLightboxIndex(0);
        if (slider && slider.current) {
            slider.current.slickGoTo(0, true);
        }

        try {
            document.getElementById('thumbs-container').scrollTo({left: 0});
        } catch (ex) {
        }

    }, [vehicleId]);

    // useEffect(() => {
    //     function handleResize() {
    //         if (imgRef && imgRef.current) {
    //             const newHeight = imgRef.current.offsetHeight;
    //             if (newHeight !== thumbsHeight) {
    //                 setThumbsHeight(newHeight)
    //             }
    //         }
    //     }
    //
    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, [width, height])

    useEffect(() => {
        if (imgRef && imgRef.current) {

            const newHeight = imgRef.current.offsetHeight;
            if (newHeight !== thumbsHeight) {
                setThumbsHeight(newHeight)
            }
        }
    }, [width, height])

    const onImageLoad = (img) => {
        if (img && img.clientHeight !== thumbsHeight) {
            setThumbsHeight(img.getBoundingClientRect().height)
        }

        if (img) {
            setSliderImageWidth(img.getBoundingClientRect().width)
        }

        if(sliderContainer && sliderContainer.current){
            setSliderContentWidth(sliderContainer.current.clientWidth);
        }
    }

    const PrevArrow = ({className, style, onClick}) => {
        return <div className="bg-black opacity-50 hover:opacity-75 opacity:bg-black flex items-center justify-center absolute left-0 cursor-pointer text-white z-10"
                    style={{right: 31, width: 40, height: 60, top: "50%", transform: "translateY(-50%)"}}
                    onClick={onClick}>
            <i className="fa fa-chevron-left text-3xl"/>
        </div>
    };

    const NextArrow = ({className, style, onClick}) => {
        return <div className="bg-black opacity-50 hover:opacity-75 opacity:bg-black flex items-center justify-center absolute right-0 cursor-pointer text-white"
                    style={{width: 40, height: 60, top: "50%", transform: "translateY(-50%)"}}
                    onClick={onClick}><i className="fa fa-chevron-right text-3xl  text-white"/></div>
    };

    const handleClickImage = (e, image) => {
        e && e.preventDefault();
        setCurrent(image.uri);
        setCurrentLightboxIndex(parseInt(image.position) < 1 ? 0 : parseInt(image.position) - 1)
    };

    const handleCloseModal = (e) => {
        e && e.preventDefault();

        setCurrent('')
        setCurrentLightboxIndex(0)
    };

    const commonSettings = {
        initialSlide: 0,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: onePhotoSlider ? 1 : 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        beforeChange: (current, next) => {
            setSlideIndex(next);
            handleScroll(next);
        },
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: onePhotoSlider ? 1 : 1,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: onePhotoSlider ? 1 : 2,

                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: onePhotoSlider ? 1 : 4,

                }
            }
        ]
    };

    useEffect(() => {
        if (isArray && images.length > 0) {
            changePhotoIndex(images[0].uri);
        }
    }, []);


    const changePhotoIndex = (currentUri, type) => {
        const index = findIndex(images, image => image.uri === currentUri);
        const prevIndex = index - 1 < 1 ? 0 : index - 1;
        const nextIndex = index + 1 >= images.length - 1 ? images.length - 1 : index + 1;


        if (type === 'prev') {
            setCurrent(images[prevIndex].uri)
            handleScroll(prevIndex)
        }

        if (type === 'next') {
            setCurrent(images[nextIndex].uri)
            handleScroll(nextIndex)
        }
    };


    const settings = onePhotoSlider ? commonSettings : {...commonSettings, className: "center", centerMode: true, centerPadding: "60px"};


    let tmpImages = images;

    if (images360 && images360.length > 0) {
        tmpImages = [{
            ...images[0],
            type: 'image_360',
            position: 0,
        }, ...tmpImages]
    }

    const sortedImages = sortBy(tmpImages, [function (img) {
        return parseInt(img.position)
    }]);

    // If they are not already sorted, move first two to the end of the array. The slider shows first two and the last two on load
    const sortedImageByQuality = photosSorted ? sortedImages : sortedImages.length > 4 ? [...sortedImages.slice(2, sortedImages.length), ...sortedImages.slice(0, 2)] : sortedImages

    const refs = sortedImages.reduce((acc, value, index) => {
        acc[`thumb_${index}`] = React.createRef();
        return acc;
    }, {});


    const handleScroll = (index) => {
        if (showThumbs && !current) {
            if (thumbsPosition === 'bottom') {
                if (refs && refs[`thumb_${index}`] && refs[`thumb_${index}`].current) {
                    const offset = refs[`thumb_${index}`].current.offsetLeft - 90;
                    // document.getElementById('thumbs-container').scrollLeft = offset;

                    document.getElementById('thumbs-container').scrollTo({
                        left: offset,
                        behavior: 'smooth',
                        // block: 'start',
                    });
                }
            }

            if (thumbsPosition === 'left') {
                if (refs && refs[`thumb_${index}`] && refs[`thumb_${index}`].current) {
                    const offset = index === 0 ? 0 : refs[`thumb_${index - 1}`].current.offsetTop - refs[`thumb_${index - 1}`].current.clientHeight;

                    document.getElementById('thumbs-container').scrollTo({
                        top: offset,
                        behavior: 'smooth',
                        // block: 'start',
                    });
                }
            }
        }
    };


    const handleSliderImageClick = (type, index) => {
        if (type && type === 'image_360') {
            setImages360Visible(true)

        } else {
            setImages360Visible(false)
            // slider.current.slickGoTo(index, true)
            setSliderImageIndex(index)
        }
    }

    const handlersLightbox = useSwipeable({
        onSwipedLeft: () => handleNextClick(),
        onSwipedRight: () => handlePrevClick(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });

    const onLightboxImgLoad = e => {
        if (lightboxContainer && lightboxContainer.current) {
            setLightboxWidth(lightboxContainer.current.offsetWidth);
        }
    }

    const handlePrevClick = () => {
        if (currentLightBoxIndex > 0) {
            const newIndex = currentLightBoxIndex > 0 ? currentLightBoxIndex - 1 : sortedImageByQuality.length - 1;
            setCurrentLightboxIndex(newIndex);
        }

    }

    const handleNextClick = () => {
        if (currentLightBoxIndex < images.length - 1) {
            const newIndex = currentLightBoxIndex + 1 < sortedImageByQuality.length ? currentLightBoxIndex + 1 : 0;
            setCurrentLightboxIndex(newIndex);
        }
    }

    const renderLightBox = () => {
        let left = currentLightBoxIndex * lightboxWidth;

        let imageWidth = "100%";
        if (width === 1024) imageWidth = "70%";

        // const formattedImages = images360 && images360.length > 0 ? sortedImageByQuality.slice(1, sortedImageByQuality.length) : sortedImageByQuality
        const formattedImages = filter(sortedImageByQuality, img => img.type !== 'image_360')


        return <div ref={lightboxContainer} className="flex items-center fixed right-0 left-0 top-0 bottom-0 text-white" style={{background: 'rgba(0, 0, 0, 0.8)', zIndex: 99999999}}>
            <div className="absolute cursor-pointer" style={{right: 20, top: 20, zIndex: 99999999}} onClick={() => handleCloseModal()}><i className="fal fa-times" style={{fontSize: 35}}/></div>

            <div className="bg-black opacity-50 hover:opacity-75 opacity:bg-black flex items-center justify-center absolute left-0 cursor-pointer text-white z-10" style={{width: 40, height: 60}} onClick={handlePrevClick}>
                <i className="far fa-chevron-left text-2xl"/>
            </div>

            <div className="bg-black opacity-50 hover:opacity-75 opacity:bg-black flex items-center justify-center absolute right-0 cursor-pointer text-white z-10" style={{width: 40, height: 60}} onClick={handleNextClick}>
                <i className="far fa-chevron-right text-2xl"/>
            </div>


            <div {...handlersLightbox}>
                <div className="images-container flex flex-nowrap relative" style={{left: -left}}>
                    {sortBy(formattedImages, img => parseInt(img.position)).map(img => {
                        return <div className="flex items-center justify-center h-full" id={img.position} style={{width: lightboxWidth}}>
                            <img src={img.uri}
                                 key={`lightbox-${img.uri}`}
                                 alt={img.uri}
                                 style={{maxWidth: imageWidth, maxHeight: "80%", pointerEvents: 'none'}}
                                 onLoad={onLightboxImgLoad}
                            />
                        </div>
                    })}
                </div>
            </div>
        </div>
    }

    const handleSliderPrevClick = (e) => {
        e.stopPropagation();
        const newIndex = sliderImageIndex > 0 ? sliderImageIndex - 1 : sortedImageByQuality.length - 1;
        setSliderImageIndex(newIndex);
    }

    const handleSliderNextClick = (e) => {
        e.stopPropagation();
        const newIndex = sliderImageIndex + 1 < sortedImageByQuality.length ? sliderImageIndex + 1 : 0;
        setSliderImageIndex(newIndex);
    }

    const renderSlider = () => {
        let left = sliderImageIndex * sliderImageWidth;

        return <div className="relative flex items-center h-full">
            <div className="bg-black opacity-50 hover:opacity-75 opacity:bg-black flex items-center justify-center absolute z-10 left-0 cursor-pointer text-white p-3"
                 onClick={e => handleSliderPrevClick(e)}>
                <i className="fa fa-chevron-left text-3xl text-white"/>
            </div>
            <div className="bg-black opacity-50 hover:opacity-75 opacity:bg-black flex items-center justify-center absolute z-10 right-0 cursor-pointer text-white p-3"
                 onClick={e => handleSliderNextClick(e)}>
                <i className="fa fa-chevron-right text-3xl text-white"/>
            </div>

            <div className="images-container flex flex-nowrap relative items-start h-full" style={{left: -left}}>
                {sortedImageByQuality.map((image, i) => {
                    return renderSlide && typeof renderSlide === 'function'
                        ? <div key={`thumb-${i}`}>{renderSlide(image, onImageLoad, handleClickImage, imgRef, interior, () => setInterior(!interior))}</div>
                        : <div className={`flex items-center justify-center h-full overflow-hidden ${imgClassName}`} style={{width: sliderContentWidth > 0 ? sliderContentWidth : "100%"}}>
                            <img src={image.uri}
                                 alt={`image_${i}`}
                                 onLoad={e => onImageLoad(e.target)}
                                 ref={imgRef}
                                 style={{width: "100%", cursor: "grab", objectFit: "cover"}}
                                 onClick={e => handleClickImage(e, image)}/>
                        </div>
                })}
            </div>
        </div>
    }



    const renderDefaultContent = () => {
        return (
            <div className={`${className} overflow-hidden`}>


                <div ref={sliderContainer} className="flex flex-1 flex-col overflow-hidden" style={{height: thumbsHeight}}>
                    {images360Visible && <Turntable images={map(images360, (img) => img.uri)} onLoad={(e) => onImageLoad(e.target)}/>}

                    {!images360Visible && renderSlider()}

                    {!images360Visible && current && renderLightBox()}
                </div>

                {images360 && images360.length > 0 &&
                <div className="flex flex-1 justify-center md:hidden mt-2">
                    <div className="flex bg-secondary rounded-full w-1/2 items-center justify-center " style={{minHeight: 43}}>
                        <div className={`flex flex-1 items-center pl-4 rounded-l-full ${images360Visible ? 'text-white bg-primary photo-slider-btn-active' : 'bg-secondary photo-slider-btn'}`}
                             style={{minHeight: 43}}
                             onClick={() => setImages360Visible(!images360Visible)}>
                            360°
                        </div>
                        <div className={`flex flex-1 items-center pl-4 rounded-r-full ${!images360Visible ? 'text-white  bg-primary photo-slider-btn-active' : 'bg-secondary photo-slider-btn'}`}
                             style={{minHeight: 43}}
                             onClick={() => setImages360Visible(!images360Visible)}>
                            {t('Pictures')}
                        </div>
                    </div>
                </div>}


                {showThumbs && images.length > 1 && <div id="thumbs-container" className="flex flex-nowrap overflow-x-auto mt-2 relative disable-scrollbars border-b border-gray-100 md:border-0">
                    {sortedImageByQuality.map((img, i) => {
                        const isLast = sortedImageByQuality.length - 1 === i;

                        const img_index = findIndex(sortedImageByQuality, image => image.uri === img.uri && !image.type);


                        if (img.type === 'image_360') {
                            return (
                                <div className={`relative cursor-pointer ${isLast ? "mr-0" : "mr-2"}`}>

                                    <div className={`flex flex-1 absolute top-0 bottom-0 left-0 right-0 ${images360Visible ? 'opacity-100' : 'opacity-75'}`} onClick={() => handleSliderImageClick(img.type, img_index)}>
                                        <div className="flex items-center justify-center rounded-full"
                                             style={{width: 36, height: 36, backgroundColor: '#344248CC', margin: 'auto'}}>
                                            <img src={require("./common/image-turntable/360-camera.png")} alt="360" style={{width: 25}}/>
                                        </div>
                                    </div>

                                    <div key={`thumb_${i}`}
                                         style={{width: 120, height: 90, objectFit: 'cover', flex: "0 0 auto"}}
                                         className={`cursor-pointer ${thumbImgClassName}`}
                                         ref={refs[`thumb_${i}`]}
                                         onClick={() => handleSliderImageClick(img.type, img_index)}>
                                        <img src={img.uri} alt="Img" title="Img" className={thumbImgClassName} style={{width: 120, height: 90, objectFit: 'cover'}}/>
                                    </div>
                                </div>
                            )
                        }


                        return (
                            <div key={`thumb_${i}`}
                                 style={{width: 120, height: 90, objectFit: 'cover', flex: "0 0 auto"}}
                                 className={`cursor-pointer ${thumbImgClassName} ${isLast ? "mr-0" : "mr-2"} ${(!images360Visible && img_index === sliderImageIndex) ? 'opacity-100' : 'opacity-75'}`}
                                 ref={refs[`thumb_${i}`]}
                                 onClick={() => handleSliderImageClick(img.type, img_index)}>
                                <img src={img.uri} alt="Img" title="Img" className={thumbImgClassName} style={{width: 120, height: 90, objectFit: 'cover'}}/>
                            </div>
                        )
                    })}
                </div>}
            </div>
        )
    }

    const calculateThumbsWidth = () => {
        let thumbsColWidth = 160
        if (width >= 1024 && width < 1476) thumbsColWidth = 80
        return thumbsColWidth
    }


    const renderContentWithThumbsLeft = () => {
        return <div className={`flex ${className}`}>
            {images.length > 1 && renderThumbs && typeof renderThumbs === 'function'
                ? renderThumbs(thumbsHeight, sortedImageByQuality, (type, img_index) => handleSliderImageClick(type, img_index), handleClickImage, slideIndex, refs)
                : <div className="flex flex-col lg:mr-2 xl:mr-5 " style={{height: thumbsHeight, width: calculateThumbsWidth()}}>
                    <div id="thumbs-container" className="overflow-y-scroll flex flex-1 flex-col disable-scrollbars" style={{height: thumbsHeight - 66}}>

                        {sortedImageByQuality.map((img, i) => {
                            const img_index = findIndex(sortedImageByQuality, image => image.uri === img.uri && !image.type);

                            if (img.type === 'image_360') {
                                return (
                                    <div className={`relative cursor-pointer ${images360Visible ? 'opacity-100' : 'opacity-75'}`}>

                                        <div className="flex flex-1 absolute top-0 bottom-0 left-0 right-0" onClick={() => handleSliderImageClick(img.type, img_index)}>
                                            <div className="flex items-center justify-center rounded-full"
                                                 style={{width: 36, height: 36, backgroundColor: '#344248CC', margin: 'auto'}}>
                                                <img src={require("./common/image-turntable/360-camera.png")} alt="360" style={{width: 25}}/>
                                            </div>
                                        </div>

                                        <div key={`thumb_${i}`}
                                             ref={refs[`thumb_${i}`]}
                                             className={`cursor-grab rounded-10 mb-2`}
                                             style={{maxHeight: 120}}
                                             onClick={() => handleSliderImageClick(img.type, img_index)}>
                                            <img src={img.uri} alt="Img" title="Img" className={thumbImgClassName} style={{height: "100%", width: '100%', objectFit: 'cover'}}/>
                                        </div>
                                    </div>
                                )
                            }

                            return (
                                <div key={`thumb_${i}`}
                                     ref={refs[`thumb_${i}`]}
                                     style={{maxHeight: 120}}
                                     className={`cursor-pointer rounded-10 mb-2  ${!images360Visible && img_index === sliderImageIndex ? 'opacity-100' : 'opacity-75'}`}
                                     onClick={() => handleSliderImageClick(img.type, img_index)}>
                                    <img src={img.uri} alt="Img" title="Img" className={thumbImgClassName} style={{height: "100%", width: '100%', objectFit: 'cover'}}/>
                                </div>
                            )
                        })}
                    </div>
                    {renderAllPicturesThumb && typeof renderAllPicturesThumb === 'function'
                        ? renderAllPicturesThumb(e => handleClickImage(e, head(sortedImageByQuality)))
                        : <div className="flex items-center justify-center cursor-pointer text-primary font-secondary" onClick={e => handleClickImage(e, head(sortedImageByQuality))}
                               style={{height: 66}}>
                            <i className="far fa-search-plus mr-2"/> {t("All pictures")}
                        </div>}

                </div>}

            <div ref={sliderContainer} className="flex flex-1 flex-col overflow-hidden" style={{height: thumbsHeight}}>
                {images360Visible && <Turntable images={map(images360, (img) => img.uri)} onLoad={(e) => onImageLoad(e.target)}/>}

                {!images360Visible && renderSlider()}

                {!images360Visible && current && renderLightBox()}
            </div>
        </div>
    }

    const renderContent = () => {
        if (showThumbs) {
            if (thumbsPosition === 'bottom') {
                return renderDefaultContent()
            }
            if (thumbsPosition === 'left') {
                return renderContentWithThumbsLeft()
            }
        }
        return renderDefaultContent();
    }

    return (
        <div>
            {!isArray || (isArray && images.length === 0) && <img src={defaultImage} alt="Img" style={{width: "100%"}}/>}
            {isArray && images.length > 0 && renderContent()}
        </div>
    )
};

PhotoSlider.defaultProps = {
    onePhotoSlider: false,
    className: "",
    showThumbs: false,
    enabled3D: false,
    thumbsPosition: 'bottom',
    photosSorted: false,
    thumbImgClassName: 'rounded-10',
    imgClassName: 'rounded-10',
    defaultImage: require('./vehicle/vehicle-basic-takeover/images/no-vehicle-photo.jpg'),
    vehicleId: undefined, // used when changing vehicle (ex demey with next prev buttons on detail)
};

export default PhotoSlider
