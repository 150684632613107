import React, {useContext, useEffect} from 'react';
import DataContext from "../../store/DataContext";

const GlobalModal = ({context}) => {
    const {globalModalComponent} = useContext(DataContext);

    useEffect(() => {
        if(globalModalComponent !== null && globalModalComponent !== undefined){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'unset';
        }
    })


    if(globalModalComponent === null || globalModalComponent === undefined) return null;
    return (
        <div className="fixed top-0 bottom-0 right-0 left-0" style={{zIndex: 9999999999, background: "rgba(0,0,0,0.7)"}}>
            {globalModalComponent}
        </div>
    );
};

export default GlobalModal;
