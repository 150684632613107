export const defaultSettings = {
    META_TITLE: "",
    META_DESCRIPTION: "",
    DOMAIN: "localhost:8000",

    TOKEN_EXPERT: "",
    CMS_USERNAME: '',
    CMS_PASSWORD: '',

    GOOGLE_IDENTIFICATION: "",
    GOOGLE_ANALYTICS_ID: '',
    GOOGLE_TAG_MANAGER_ID: '',
    HOTJAR_ID: '',
    INTERCOM_ID: '',

    CAPTCHA_KEY: "6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3",


    FINANCING: false,
    RESERVATION: false,
    FAVORITES: false,
    FINANCING_TEXT: true,
    VEHICLE_COMPARE: false,
    VEHICLES_BASED_ON_URL: false,
    VEHICLE_SEGMENTATION: false,
    VEHICLES_PRELOAD: false,

    // Example 'engineCc;desc' can be used
    DEFAULT_VEHICLES_SORT: '',

    MAPBOX_TOKEN: "pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w"
};


// Google analytics demey: UA-162310885-2
// Google analytics phlips: UA-162310885-3
// hotjar demey: 1749511
// hotjar phlips: 1749519




