import React from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const CaptchaProvider = ({captchaKey, children}) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
            {children}
        </GoogleReCaptchaProvider>
    );
};

export default CaptchaProvider;
