import React from 'react';

const InputField = ({containerClass, label, placeholder, value, onChange, errorText, required, type, withIcon, icon, errorClass, inputClass, onlyNumbers, lightForm, isPhone}) => {

    const hasError = errorText !== undefined;
    return (
        <div className={containerClass}>
            {label && <label className={`block tracking-wide ${hasError ? "text-red-400" : lightForm ? "text-white" : "text-gray-700"} mb-2`} >{label} {required ? "(*)" : ""}</label>}
            <div className="flex">
                {withIcon && <div className="flex -mr-px">
                    <span className={`flex items-center leading-normal rounded rounded-r-none ${lightForm ? "text-white" : "text-gray-700  border"} border-r-0 ${hasError ? "bg-red-100 border-red-400 text-red-900" : lightForm ? "bg-transparent border-b border-white" : "bg-gray-100 border-gray-400"} px-3 whitespace-nowrap text-sm`}>
                        <i className={`${icon}`} />
                    </span>
                </div>}
                <input className={`appearance-none block w-full ${lightForm ? "text-white focus:border-gray-400 rounded-none px-0" : "text-gray-700 border focus:border-primary px-4"} ${hasError ? "bg-red-100 border-red-400 text-red-900" : lightForm ? "bg-transparent border-b border-white" : "bg-white border-gray-400"}  ${withIcon && "rounded-l-none"} py-3 focus:outline-none ${inputClass}`}
                       type={type}
                       placeholder={placeholder}
                       value={value}
                       onChange={e => {
                           if(onlyNumbers){
                               const re = /^[0-9\b]+$/;
                               if(e.target.value === '' || re.test(e.target.value)){
                                   onChange(e.target.value)
                               }
                           }else if(isPhone){
                               const re = /^[- +()0-9\b]+$/;
                               if(e.target.value === '' || re.test(e.target.value)){
                                   onChange(e.target.value)
                               }
                           }else{
                               onChange(e.target.value)
                           }

                       }}
                />
            </div>
            {hasError && errorText !== '' && <div className={`text-red-400 text-xs mt-1 ${errorClass}`}>{errorText}</div>}
        </div>
    );
};

InputField.defaultProps = {
    containerClass: 'text-sm font-bold',
    type: "text",
    required: false,
    withIcon: false,
    icon: "",
    errorClass: "",
    inputClass: "rounded",
    onlyNumbers: false,
    lightForm: false,
    isPhone: false
};

export default InputField;
