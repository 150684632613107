import React from 'react';
import {VehiclesSlider} from "../../../core";
import {navigatePage} from "../../../core/utils";
import {LegalContent} from "../../../core/components/legal";
import VehicleSlider_Preview from "./components/VehicleSlider_Preview";

const VehiclesSliderWrapper = ({custom_card_image, custom_card_action, context}) => {
    return (
        <div className="flex flex-col">
            <VehiclesSlider context={context}
                            vehicle_count={10}
                            custom_card_image={custom_card_image}
                            custom_card_action={custom_card_action}
                            filter={{limit: 10}}
                            swipeEnabled={false}
                            onVehicleClick={(vehicle) => navigatePage(context, context.urls.vehicleDetail, `reference=${vehicle.reference}`)}
            />

            <div className={`flex flex-row text-center items-center text-xl md:text-3xl justify-center hover:underline mt-5 cursor-pointer ${!context.financing ? 'mb-5' : ''}`}
                 onClick={() => navigatePage(context, context.urls.vehicles)}>
                <i className="far fa-plus mr-3"/>Ontdek onze voorraad
            </div>

            {context.financing &&
            <div className="mt-2 bg-white text-gray-600">
                <LegalContent context={context}/>
            </div>}

        </div>
    );
};

VehiclesSliderWrapper.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

VehiclesSliderWrapper.preview = () => { return <VehicleSlider_Preview /> };

export default VehiclesSliderWrapper;
